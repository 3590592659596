var slider;

slider = function() {
  var Swiper, galleryTop, sertSlider;
  Swiper = require('swiper');
  galleryTop = new Swiper('.swiper-container.gal__top', {
    prevButton: '.slider__chev.slider__chev--left#gal-left-chev',
    nextButton: '.slider__chev.slider__chev--right#gal-right-chev',
    pagination: '.slider__pagination#gal-pagination',
    paginationClickable: true,
    bulletClass: 'slider__bullet',
    bulletActiveClass: 'slider__bullet--active',
    paginationCurrentClass: 'slider__bullet--current',
    autoplay: 3000
  });
  return sertSlider = new Swiper('.serts__slider.swiper-container', {
    slidesPerView: 3,
    centeredSlides: true,
    prevButton: '.slider__chev.slider__chev--left#sert-left-chev',
    nextButton: '.slider__chev.slider__chev--right#sert-right-chev',
    pagination: '.slider__pagination#sert-pagination',
    paginationClickable: true,
    bulletClass: 'slider__bullet',
    bulletActiveClass: 'slider__bullet--active',
    paginationCurrentClass: 'slider__bullet--current',
    autoplay: 3000,
    breakpoints: {
      767: {
        slidesPerView: 1
      }
    }
  });
};

module.exports = slider;
