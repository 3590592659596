var box, callmodule, checkbox, dataConfirm, gallery, initTarget, jform, slider, tab;

box = require('../_modules/components/box/box');

slider = require('../_modules/components/slider/slider');

tab = require('../_modules/components/tab/tab');

gallery = require('../_modules/components/gallery/gallery');

callmodule = require('./functions/callmodule');

jform = require('../_modules/components/jform/jform');

initTarget = require('./functions/init-target');

dataConfirm = require('../_modules/components/data-confirm/data-confirm');

checkbox = require('../_modules/components/checkbox/checkbox');

document.addEventListener('DOMContentLoaded', function() {
  new box();
  new slider();
  new tab();
  new gallery();
  new callmodule();
  new jform();
  new initTarget();
  new dataConfirm();
  return new checkbox();
});
