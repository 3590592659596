var dataConfirm;

dataConfirm = function() {
  var $, module, moduleName;
  $ = require('jquery');
  moduleName = 'data-confirm';
  module = $("." + moduleName);
  console.log(module);
  return module.each(function() {
    var btn, propDisabledBtn, that, toggler, togglerInput;
    that = $(this);
    toggler = that.find("" + (that.data('toggler')));
    btn = that.find("" + (that.data('btn')));
    console.log(toggler, btn);
    togglerInput = toggler.find("input" + (that.data('toggler')) + "__status");
    togglerInput.change(function() {
      if (btn.attr('disabled')) {
        return btn.removeAttr('disabled');
      } else {
        return btn.attr('disabled', 'disabled');
      }
    });
    propDisabledBtn = btn.attr('disabled');
    if (typeof propDisabledBtn !== void 0 || propDisabledBtn !== false) {
      return btn.attr('disabled', 'disabled');
    }
  });
};

module.exports = dataConfirm;
