var tab;

tab = function() {
  var $, activeClass, btn, content, module, moduleName, toggleTab, xsChev;
  $ = require('jquery');
  moduleName = 'tab';
  module = $("." + moduleName);
  activeClass = 'active';
  btn = module.find("." + moduleName + "__btn");
  content = module.find("." + moduleName + "__content");
  xsChev = module.find("." + moduleName + "__chev");
  toggleTab = function(obj) {
    var thisData;
    obj.addClass(moduleName + "__btn--" + activeClass);
    obj.siblings().removeClass(moduleName + "__btn--" + activeClass);
    thisData = obj.data("" + moduleName);
    console.log(thisData);
    return content.each(function() {
      var thisContent, thisContentData;
      thisContent = $(this);
      thisContentData = thisContent.data('tab');
      if (thisContentData === thisData) {
        thisContent.addClass(moduleName + "__content--" + activeClass);
        return thisContent.siblings().removeClass(moduleName + "__content--" + activeClass);
      }
    });
  };
  btn.click(function() {
    var thisBtn;
    thisBtn = $(this);
    return toggleTab(thisBtn);
  });
  return xsChev.click(function() {
    return btn.each(function() {
      var thisBtn;
      thisBtn = $(this);
      if (thisBtn.hasClass(moduleName + "__btn--" + activeClass) !== true) {
        return setTimeout(function() {
          return toggleTab(thisBtn);
        }, 10);
      }
    });
  });
};

module.exports = tab;
