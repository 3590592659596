var jform;

jform = function() {
  var $, fastform, moduleName, resCall;
  $ = require('jquery');
  resCall = require('../modal/modal');
  moduleName = 'jform';
  fastform = $("." + moduleName);
  return fastform.submit(function(e) {
    var ref, thisField, thisFieldSpamValue, thisFieldValue, thisFieldValueNum, thisForm, thisSubmitter, url;
    e.preventDefault();
    thisForm = $(this);
    thisSubmitter = thisForm.find("." + moduleName + "__submit");
    thisField = thisForm.find("." + moduleName + "__input");
    thisFieldValue = thisField.val();
    thisFieldSpamValue = thisForm.find("." + moduleName + "__workemail").val();
    if (thisFieldSpamValue === '') {
      if (thisFieldValue !== '') {
        thisFieldValueNum = thisForm.find("." + moduleName + "__input:not(." + moduleName + "__input--def)").val();
        if (thisFieldValueNum.match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/)) {
          url = (ref = typeof thisForm.attr("action") === 'undefined') != null ? ref : {
            "[[~[[*id]]]]": thisForm.attr("action")
          };
          return $.ajax({
            url: url,
            type: 'POST',
            data: thisForm.serialize(),
            success: function(response) {
              return resCall('Долго ждать?<br>Наберите нас прямо сейчас: ‎8 (499) 991-21-61', 'Спасибо! Ваша заявка отправлена!', true);
            }
          }).fail(function(error) {
            console.log(error);
            return resCall('Возникла ошибка на сервере', 'Перезагрузите страницу', false);
          });
        } else {
          return resCall('Возникла ошибка в форме', 'Убедитесь в правильности номера', false);
        }
      } else {
        return resCall('Возникла ошибка в форме', 'Поля не заполнены', false);
      }
    } else {
      return alert('Вызов модалки - спам');
    }
  });
};

module.exports = jform;
