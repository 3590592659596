var initTarget;

initTarget = function() {
  var $, targetBtn;
  $ = require('jquery');
  targetBtn = $('[data-target]');
  return targetBtn.click(function(e) {
    var thisTarget;
    e.preventDefault();
    thisTarget = $(this).data('target');
    return yaCounter44823718.reachGoal(thisTarget);
  });
};

module.exports = initTarget;
