var gallery;

gallery = function() {
  var $, createLayout, galLink, imageRemover, initGhostSlider, module, moduleName, removeLayout, swiper;
  $ = require('jquery');
  moduleName = 'lightbox';
  module = $("." + moduleName);
  swiper = require('swiper');
  galLink = module.find("." + moduleName + "__link");
  imageRemover = module.find("." + moduleName + "__rm");
  createLayout = function() {
    var allSlides, i, layout, len, results, slide;
    layout = '<div class="ghost-gal"> <div class="ghost-gal__close"></div> <div class="ghost-gal__container swiper-container"> <div class="ghost-gal__wrapper swiper-wrapper"> </div> <span class="ghost-gal__chev ghost-gal__chev--left"></span> <span class="ghost-gal__chev ghost-gal__chev--right"></span> </div> </div>';
    $('body').append(layout);
    allSlides = [];
    galLink.each(function() {
      var slide, thiGalAlt, thisGal, thisGalSrc;
      thisGal = $(this);
      thisGalSrc = thisGal.attr('href');
      thiGalAlt = thisGal.data('alt');
      slide = '<div class="ghost-gal__slide swiper-slide"> <img src ="' + thisGalSrc + '" alt ="' + thiGalAlt + '"> </div>';
      return allSlides.push(slide);
    });
    results = [];
    for (i = 0, len = allSlides.length; i < len; i++) {
      slide = allSlides[i];
      results.push((function(slide) {
        return $('.ghost-gal__wrapper').append(slide);
      })(slide));
    }
    return results;
  };
  initGhostSlider = function(order) {
    var ghostSlider;
    ghostSlider = new Swiper('.ghost-gal__container', {
      nextButton: '.ghost-gal__chev--right',
      prevButton: '.ghost-gal__chev--left'
    });
    return ghostSlider.slideTo(order);
  };
  removeLayout = function() {
    var layout;
    layout = $('.ghost-gal');
    if (layout.hength !== 0) {
      layout.removeClass('ghost-gal--enabled');
      return setTimeout(function() {
        return layout.remove();
      }, 200);
    } else {
      return console.warn('layout ghost-gal is not exists');
    }
  };
  galLink.click(function(e) {
    var qThisLayout, thisClose, thisLayout, thisLink, thisLinkOrder;
    e.preventDefault();
    thisLink = $(this);
    thisLinkOrder = thisLink.parents(".swiper-slide").index();
    createLayout();
    initGhostSlider(thisLinkOrder);
    thisLayout = 'ghost-gal';
    qThisLayout = $("." + thisLayout);
    setTimeout(function() {
      return qThisLayout.addClass(thisLayout + "--enabled");
    }, 200);
    thisClose = $("." + thisLayout + "__close");
    return thisClose.click(function() {
      return removeLayout();
    });
  });
  return imageRemover.click(function() {
    var thisLink, thisRemover;
    thisRemover = $(this);
    thisLink = thisRemover.parent("." + moduleName + "__link");
    thisLink.removeAttr('href');
    return thisLink.remove();
  });
};

module.exports = gallery;
