var checkbox;

checkbox = function() {
  var $, module, moduleName;
  $ = require('jquery');
  moduleName = "checkbox";
  module = $("." + moduleName);
  return module.click(function() {
    var thisChecker, thisModule, thisStatus, thisStatusValue;
    thisModule = $(this);
    thisChecker = thisModule.find("." + moduleName + "__checker");
    thisChecker.toggleClass(moduleName + "__checker--enabled");
    thisStatus = thisModule.find("." + moduleName + "__status");
    thisStatusValue = thisStatus.val();
    if (thisStatusValue === 'on') {
      return thisStatus.val('off').change();
    } else {
      return thisStatus.val('on').change();
    }
  });
};

module.exports = checkbox;
